<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>
        <b>Obrigado!</b>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-progress-linear
      height="5"
      color="success"
      :value="countdown"
    />
    <v-card-text>
      <v-row>
        <v-col
          class="text-center"
        >
          <p>Suas respostas foram registradas com sucesso!</p>
          <p>Obrigado por responder a pesquisa.</p>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
  export default {

    data: () => ({
      countdown: 100,
      interval: null,
    }),

    mounted () {
      this.interval = setInterval(() => {
        this.countdown = this.countdown - 1

        if (this.countdown <= 0) {
          clearInterval(this.interval)
          this.$store.dispatch('logoutContact')
          this.$router.push(
            `/responder-pesquisa/${this.$route.params.surveyId}/login`
          )
          return
        }
      }, 50)
    },

    methods: { },

  }
</script>
